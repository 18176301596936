import { navLinks, navSocial } from "../data";
import christianLogo from "../images/christian-logo.webp";
import { useRef } from "react";

const NavItem = ({ href, text }) => {
    return (
        <li>
            <a href={href} className="nav-link">
                {text}
            </a>
        </li>
    );
};

const NavSocialItem = ({ href, icon, target }) => {
    return (
        <li>
            <a
                href={href}
                target={target}
                className="nav-icon"
                rel="noreferrer"
            >
                <i className={icon}></i>
            </a>
        </li>
    );
};

const NavBar = () => {
    let refMenu = useRef();
    let refNav = useRef();

    const handleToggleMenu = (e) => {
        if (refMenu.current.classList.contains("inactive")) {
            refNav.current.classList.add("nav-mobile");
            refMenu.current.classList.replace("inactive", "active");
        } else {
            refNav.current.classList.remove("nav-mobile");
            refMenu.current.classList.replace("active", "inactive");
            refNav.current.style.overflow("hidden");
        }

        // if (refMenu.current.classList.contains("active")) {
        //     console.log("se debe cerrar el menu");
        //     if (e.target == refMenu) {
        //         console.log("se debe cerrar el menu");
        //     }
        // }

        // si "nav-toggle active" esta activo y se le da click a "nav-links nav-mobile" se debe quitar la clase "nav-toggle active"

        // $(".products-nutrition-modal").click(function (event) {
        //     if (event.target !== event.currentTarget) {
        //         return;
        //     }
        //     $(this).css("display", "none");
        // });
    };

    return (
        <>
            <nav className="navbar">
                <div className="nav-center">
                    <div className="nav-header">
                        <img
                            src={christianLogo}
                            className="nav-logo"
                            alt="Christian Cervantes Developer"
                        />
                        <button
                            type="button"
                            className="nav-toggle inactive"
                            id="nav-toggle"
                            ref={refMenu}
                            onClick={handleToggleMenu}
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>
                    {/* <!-- left this comment on purpose --> */}
                    <ul className="nav-links" id="nav-links" ref={refNav}>
                        {navLinks.map((el) => {
                            return <NavItem {...el} key={el.id} />;
                        })}
                    </ul>
                </div>
            </nav>
            <ul className="nav-icons">
                {navSocial.map((el) => {
                    return <NavSocialItem {...el} key={el.id} />;
                })}
            </ul>
        </>
    );
};
export default NavBar;
