import Title from "./general/Title";
import { aboutSkills } from "../data";

const Skills = ({ text }) => {
    return <div className="about-skills-content-item">{text}</div>;
};

const About = () => {
    return (
        <section className="section" id="about">
            <Title title="about" spanTitle="me" />
            <div className="section-center about-center">
                <article className="about-info">
                    <h3>have a look</h3>
                    <p className="about__content-details-para">
                        I'm a <strong>web developer</strong> focused on building
                        personalized and dynamic websites with the highest
                        standards and eye for detail. Check out some of my work
                        in the <strong>Projects</strong> section.
                    </p>
                    <p className="about__content-details-para">
                        I like new challenges that lead me to demand and focus
                        more to demonstrate my skills and great experience in
                        the field of <strong>Web Development</strong> , feel
                        free to contact me or follow me on my{" "}
                        <a
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/christian-cervantes-86186a1b6/?locale=en_US"
                            target="_blank"
                        >
                            {" "}
                            Linkedin{" "}
                        </a>
                        .
                    </p>
                    <p className="about__content-details-para">
                        I'm open to new <strong>Job</strong> opportunities where
                        I can contribute, learn and grow. If you have a good
                        opportunity that matches my skills and experience then
                        don't hesitate to
                        <strong> contact</strong> me.
                    </p>
                    <a href="#contact" className="btn">
                        Contact
                    </a>
                </article>
                <div className="about-skills">
                    <h3 className="about-skills-title">My Skills</h3>
                    <div className="about-skills-content">
                        {aboutSkills.map((el) => {
                            return <Skills {...el} key={el.id} />;
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default About;
