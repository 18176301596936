const Hero = () => {
    return (
        <section className="hero" id="home">
            <div className="hero-banner">
                <h1>HEY, I'M CHRISTIAN CERVANTES</h1>
                <p>
                    A website developer focused on building personalized and
                    dynamic websites with the highest standards and eye for
                    detail.
                </p>
                <a href="#projects" className="btn hero-btn">
                    PROJECTS
                </a>
            </div>
        </section>
    );
};
export default Hero;
