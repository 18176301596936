import tour1 from "./images/tour-1.jpeg";
import tour2 from "./images/tour-2.jpeg";
import tour3 from "./images/tour-3.jpeg";
import tour4 from "./images/tour-4.jpeg";
import project1 from "./images/atkins-project.webp";
import project3 from "./images/29ark-project.webp";
import project4 from "./images/copa-project.webp";
import project6 from "./images/blackbaud-projects.webp";
import project7 from "./images/justgiving-projects.webp";

export const navLinks = [
    {
        id: 1,
        href: "#home",
        text: "home",
    },
    {
        id: 2,
        href: "#about",
        text: "about me",
    },
    {
        id: 3,
        href: "#projects",
        text: "projects",
    },
    {
        id: 4,
        href: "#contact",
        text: "contact",
    },
];

export const navSocial = [
    {
        id: 1,
        href: "https://www.linkedin.com/in/christian-cervantes-86186a1b6/?locale=en_US",
        icon: "fab fa-linkedin-in",
        target: "_blank",
    },
    {
        id: 2,
        href: "mailto:christian.cervantes.d@gmail.com",
        icon: "fa  fa-envelope",
        target: "",
    },
];

export const aboutSkills = [
    {
        id: 1,
        text: "HTML",
    },
    {
        id: 2,
        text: "CSS",
    },
    {
        id: 3,
        text: "Wordpress",
    },
    {
        id: 4,
        text: "PHP",
    },
    {
        id: 5,
        text: "JavaScript",
    },
    {
        id: 6,
        text: "React",
    },
    {
        id: 7,
        text: "SASS",
    },
    {
        id: 8,
        text: "GIT",
    },
    {
        id: 9,
        text: "Github",
    },
    {
        id: 10,
        text: "Responsive Design",
    },
    {
        id: 11,
        text: "SEO",
    },
    {
        id: 12,
        text: "GULP",
    },
    {
        id: 13,
        text: "SQL Basics",
    },
];

export const projects = [
    {
        id: 6,
        image: project6,
        title: "Blackbaud",
        text: "Is the leading software provider exclusively dedicated to education sectors. A website created to an exclusived client with attention to details in the design and with a firendly behavior in the admin side.",
        ctaLink: "https://www.blackbaud.com/",
        html: "HTML",
        css: "CSS",
        scss: "SCSS",
        wordpress: "Wordpress",
        react: "",
        php: "PHP",
        javascript: "JavaScript",
        gutenberg: "Custom Gutenberg Blocks",
        acf: "ACF Blocks",
        git: "GIT",
        gulp: "",
        seo: "SEO",
        customPlugin: "Custom plugin",
    },
    {
        id: 1,
        image: project1,
        title: "Atkins",
        text: "Atkins is a low carb diet program site that was move to Wordpress and re-created to improve it's performance, with attention to detail y SEO practices.",
        ctaLink: "https://www.atkins.com/",
        html: "HTML",
        css: "CSS",
        scss: "SCSS",
        wordpress: "Wordpress",
        react: "",
        php: "PHP",
        javascript: "JavaScript",
        gutenberg: "Custom Gutenberg Blocks",
        acf: "ACF Blocks",
        git: "GIT",
        gulp: "",
        seo: "SEO",
        customPlugin: "",
    },
    {
        id: 3,
        image: project3,
        title: "29 Ark design",
        text: "29 Ark is a design architecture agency with a very elegant minimalist design, developed in React to have fast page rendering and high performance.",
        ctaLink: "https://29arkarchitecture.com/",
        html: "HTML",
        css: "CSS",
        scss: "SCSS",
        wordpress: "",
        react: "REACT",
        php: "",
        javascript: "JavaScript",
        gutenberg: "",
        acf: "",
        git: "GIT",
        gulp: "",
        seo: "SEO",
        customPlugin: "",
    },
    {
        id: 7,
        image: project7,
        title: "Justgiving",
        text: "Is an online fundraising for the people and charities, developed calmly and based on a basic design but with a solid internal structure.",
        ctaLink: "https://justgiving.com/",
        html: "HTML",
        css: "CSS",
        scss: "SCSS",
        wordpress: "Wordpress",
        react: "",
        php: "PHP",
        javascript: "JavaScript",
        gutenberg: "Custom Gutenberg Blocks",
        acf: "ACF Blocks",
        git: "GIT",
        gulp: "",
        seo: "SEO",
        customPlugin: "Custom plugin",
    },
    {
        id: 4,
        image: project4,
        title: "Make it happen copa",
        text: "Make it happen is a Copa Airlines webpage with an environment to make you travel, developed in React to have fast page rendering and high performance.",
        ctaLink: "https://makeithappencopa.com/",
        html: "HTML",
        css: "CSS",
        scss: "SCSS",
        wordpress: "",
        react: "React JS",
        php: "",
        javascript: "JavaScript",
        gutenberg: "",
        acf: "",
        git: "GIT",
        gulp: "",
        seo: "SEO",
        customPlugin: "",
    },
];

export const tours = [
    {
        id: 1,
        image: tour1,
        date: "august 26th, 2020",
        title: "Tibet Adventure",
        info: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque vitae tempore voluptatum maxime reprehenderit eum quod exercitationem fugit, qui corporis.",
        location: "china",
        duration: "6 days",
        cost: "$2100",
    },
    {
        id: 2,
        image: tour2,
        date: "october 1th, 2020",
        title: "best of java",
        info: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque vitae tempore voluptatum maxime reprehenderit eum quod exercitationem fugit, qui corporis.",
        location: "indonesia",
        duration: "1 days",
        cost: "$1400",
    },
    {
        id: 3,
        image: tour3,
        date: "september 15th, 2020",
        title: "explore hong kong",
        info: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque vitae tempore voluptatum maxime reprehenderit eum quod exercitationem fugit, qui corporis.",
        location: "hong kong",
        duration: "8 days",
        cost: "$5000",
    },
    {
        id: 4,
        image: tour4,
        date: "december 5th, 2019",
        title: "kenya highlights",
        info: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque vitae tempore voluptatum maxime reprehenderit eum quod exercitationem fugit, qui corporis.",
        location: "kenya",
        duration: "20 days",
        cost: "$3300",
    },
];

export const footerLinks = [
    {
        id: 1,
        href: "#home",
        text: "home",
    },
    {
        id: 2,
        href: "#about",
        text: "about",
    },
    {
        id: 3,
        href: "#projects",
        text: "projects",
    },
    {
        id: 4,
        href: "#contact",
        text: "contact",
    },
];

export const footerSocial = [
    {
        id: 1,
        href: "https://www.linkedin.com/in/christian-cervantes-86186a1b6/?locale=en_US",
        icon: "fab fa-linkedin-in",
        target: "_blank",
    },
    {
        id: 2,
        href: "mailto:christian.cervantes.d@gmail.com",
        icon: "fa fa-envelope",
        target: "",
    },
];
