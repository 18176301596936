import Title from "./general/Title";
import ContactForm from "./general/ContactForm";

const Contact = () => {
    return (
        <section className="section contact" id="contact">
            <Title title="Contact" spanTitle="me" />
            <div className="section-center projects-center">
                <p className="paragraph-center">
                    Feel free to Contact me by submitting the form below and I
                    will contact you as soon as possible.
                </p>
                <ContactForm />
            </div>
        </section>
    );
};
export default Contact;
