import { projects } from "../data";
import Title from "./general/Title";

const UsedTools = ({ text }) => {
    return <div className="projects-container-content-skills-item">{text}</div>;
};

const ProjectsItem = ({
    image,
    title,
    text,
    ctaLink,
    html,
    css,
    scss,
    wordpress,
    react,
    php,
    javascript,
    gutenberg,
    acf,
    git,
    gulp,
    seo,
    customPlugin,
}) => {
    return (
        <div className="projects-container">
            <div className="projects-container-box-img">
                <img
                    src={image}
                    alt="Software Screenshot"
                    className="projects-container-box-img-item"
                    loading="lazy"
                />
            </div>
            <div className="projects-container-content">
                <h3 className="projects-container-content-title">{title}</h3>
                <p className="projects-container-content-text">{text}</p>
                <h4>Tools Used</h4>
                <div className="projects-container-content-skills">
                    {html ? <UsedTools text={html} /> : null}
                    {css ? <UsedTools text={css} /> : null}
                    {scss ? <UsedTools text={scss} /> : null}
                    {wordpress ? <UsedTools text={wordpress} /> : null}
                    {react ? <UsedTools text={react} /> : null}
                    {php ? <UsedTools text={php} /> : null}
                    {javascript ? <UsedTools text={javascript} /> : null}
                    {gutenberg ? <UsedTools text={gutenberg} /> : null}
                    {acf ? <UsedTools text={acf} /> : null}
                    {git ? <UsedTools text={git} /> : null}
                    {gulp ? <UsedTools text={gulp} /> : null}
                    {seo ? <UsedTools text={seo} /> : null}
                    {customPlugin ? <UsedTools text={customPlugin} /> : null}
                </div>
                <a
                    href={ctaLink}
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                >
                    Project Link
                </a>
            </div>
        </div>
    );
};

const Projects = () => {
    return (
        <section className="section projects" id="projects">
            <Title title="main" spanTitle="projects" />
            <div className="section-center projects-center">
                <div>
                    {projects.map((el) => {
                        return <ProjectsItem {...el} key={el.id} />;
                    })}
                </div>
            </div>
        </section>
    );
};
export default Projects;
